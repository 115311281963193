var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mx-0 px-0 mt-1",attrs:{"align":"center"}},[_c('v-col',{staticClass:"px-5",attrs:{"cols":"6"}},[_c('label',{staticStyle:{"font-size":"16px"}},[_vm._v("材料ID: "+_vm._s(_vm.$route.params.id))])]),_c('v-spacer'),_c('v-col',{staticClass:"px-5",attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('v-spacer'),_c('v-btn',{staticClass:"me-3",attrs:{"color":"secondary","outlined":""},on:{"click":_vm.comeback}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(" "+_vm._s(_vm.icons.mdiKeyboardReturn)+" ")]),_c('span',[_vm._v("戻る")])],1),_c('v-btn',{attrs:{"color":"secondary","loading":_vm.exportStatus,"outlined":""},on:{"click":_vm.downloadCSV}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(" "+_vm._s(_vm.icons.mdiExportVariant)+" ")]),_c('span',[_vm._v("Export")])],1)],1)])],1),_c('v-divider',{staticClass:"mt-3"}),_c('v-card-text',{staticClass:"pa-0"},[_c('v-row',{staticClass:"ma-0",attrs:{"align":"center"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.DailyMaterialStock,"group-by":"material_id","item-key":"target_date","items-per-page":-1},scopedSlots:_vm._u([{key:"item.weight_in",fn:function(ref){
var item = ref.item;
return [_c('label',[_vm._v(_vm._s(_vm.formatData(item.weight_in)))])]}},{key:"item.weight_out",fn:function(ref){
var item = ref.item;
return [_c('label',{staticStyle:{"color":"#f44336"}},[_vm._v(_vm._s(_vm.formatData(item.weight_out)))])]}},{key:"item.daily_weight",fn:function(ref){
var item = ref.item;
return [_c('label',{style:(item.daily_weight < 0 ? 'color:#F44336' : '')},[_vm._v(_vm._s(_vm.formatData(item.daily_weight)))])]}},{key:"item.net_weight",fn:function(ref){
var item = ref.item;
return [_c('label',{style:(item.net_weight < 0 ? 'color:#F44336' : '')},[_vm._v(_vm._s(_vm.formatData(item.net_weight)))])]}},{key:"group.header",fn:function(){return undefined},proxy:true},{key:"body.prepend",fn:function(){return [_c('tr',[_c('td',{staticStyle:{"background":"#ffffff"}},[_vm._v(" 前月繰越分 ")]),_c('td',{staticStyle:{"background":"#ffffff","text-align":"right"}},[_vm._v(" "+_vm._s(_vm.formatData(_vm.wgtin_lmSum))+" ")]),_c('td',{staticStyle:{"background":"#ffffff","text-align":"right","color":"#f44336 !important"}},[_vm._v(" "+_vm._s(_vm.wgtout_lmSum)+" ")]),_c('td',{style:(("background:#FFFFFF;text-align:right;" + (_vm.wgtin_lmSum - _vm.wgtout_lmSum < 0 ? 'color:#F44336 !important;' : '')))},[_vm._v(" "+_vm._s(_vm.formatData(_vm.wgtin_lmSum - _vm.wgtout_lmSum))+" ")]),_c('td',{style:(("background:#FFFFFF;text-align:right;" + (_vm.net_weight < 0 ? 'color:#F44336 !important;' : '')))},[_vm._v(" "+_vm._s(_vm.formatData(_vm.net_weight))+" ")])])]},proxy:true},{key:"group.summary",fn:function(){return [_c('td',{staticStyle:{"background":"#ffffff","text-align":"right"}}),_c('td',{staticStyle:{"background":"#ffffff","text-align":"right"}},[_vm._v(" "+_vm._s(_vm.formatData(_vm.MthWgtIn))+" ")]),_c('td',{staticStyle:{"background":"#ffffff","text-align":"right","color":"#f44336 !important"}},[_vm._v(" "+_vm._s(_vm.formatData(_vm.MthWgtOut))+" ")]),_c('td',{style:(("background:#FFFFFF;text-align:right;" + (_vm.MthWgt < 0 ? 'color:#F44336 !important;' : '')))},[_vm._v(" "+_vm._s(_vm.formatData(_vm.MthWgt))+" ")]),_c('td',{staticStyle:{"background":"#ffffff","text-align":"right"}})]},proxy:true}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }